import React, { useState } from 'react';
import { IAgoraRTCClient, ICameraVideoTrack, IMicrophoneAudioTrack } from 'agora-rtc-react';
import { IonBadge, IonFabButton, IonIcon } from '@ionic/react';
import { mic, micOff, play, settings, stop, videocam, videocamOff } from 'ionicons/icons';
import AgoraModalSettings from './modals/AgoraModalSettings';
import { useTranslation } from 'react-i18next';

import './AgoraOverlay.scss';

interface IProps {
  micTrack: IMicrophoneAudioTrack;
  videoTrack: ICameraVideoTrack;
  client: IAgoraRTCClient;
  published: boolean;
  handlePlayStop: () => void;
}

const AgoraOverlay: React.FC<IProps> = ({ micTrack, videoTrack, client, published, handlePlayStop }) => {
  const { t } = useTranslation();

  const [settingsModalIsOpen, setSettingsModalIsOpen] = useState<boolean>(false);
  const [trackState, setTrackState] = useState<{
    video: boolean;
    audio: boolean;
  }>({
    video: true,
    audio: true,
  });
  
  const [trackStateVideo, setTrackStateVideo] = useState<{
    loading: boolean;
    enabled: boolean;
  }>({
    loading: false,
    enabled: true,
  });
  const [trackStateAudio, setTrackStateAudio] = useState<{
    loading: boolean;
    enabled: boolean;
  }>({
    loading: false,
    enabled: true,
  });

  const mute = async (type: 'audio' | 'video') => {
    if (type === 'audio') {
      setTrackStateAudio((ps) => ({
        ...ps,
        loading: true,
      }));
      await micTrack.setMuted(trackStateAudio.enabled);
      setTrackStateAudio((ps) => ({
        loading: false,
        enabled: !ps.enabled,
      }));
    } else if (type === 'video') {
      setTrackStateVideo((ps) => ({
        ...ps,
        loading: true,
      }));
      console.log(trackStateVideo.enabled);
      await videoTrack.setMuted(trackStateVideo.enabled);
      setTrackStateVideo((ps) => ({
        loading: false,
        enabled: !ps.enabled,
      }));
    }
  };

  return (
    <>
      <div className="agora-overlay">
        <IonBadge color={published ? 'danger' : 'medium'} className="live-indicator">
          {published ? t('Live') : t('Offline')}
        </IonBadge>
        <div className="agora-overlay-top">
          {/*<p className={trackState.audio ? 'on' : ''}>{trackState.audio ? 'MuteAudio' : 'UnmuteAudio'}</p>
          <p className={trackState.video ? 'on' : ''} onClick={() => mute('video')}>
            {trackState.video ? 'MuteVideo' : 'UnmuteVideo'}
          </p>*/}

        </div>
        <div className="agora-overlay-bottom">
          <IonFabButton onClick={() => mute('audio')} color={trackStateAudio.enabled ? 'medium' : 'danger'} disabled={trackStateAudio.loading}>
            <IonIcon icon={trackStateAudio.enabled ? mic : micOff} />
          </IonFabButton>
          <IonFabButton onClick={() => mute('video')} color={trackStateVideo.enabled ? 'medium' : 'danger'} disabled={trackStateVideo.loading}>
            <IonIcon icon={trackStateVideo.enabled ? videocam : videocamOff} />
          </IonFabButton>
          <IonFabButton onClick={() => handlePlayStop()} color={published ? 'danger' : 'success'}>
            <IonIcon icon={published ? stop : play} />
          </IonFabButton>
          <IonFabButton onClick={() => setSettingsModalIsOpen(true)} color="medium">
            <IonIcon icon={settings} />
          </IonFabButton>
        </div>
      </div>
      <AgoraModalSettings
        micTrack={micTrack}
        videoTrack={videoTrack}
        isOpen={settingsModalIsOpen}
        onDidDismiss={() => setSettingsModalIsOpen(false)}
      />
    </>
  );
};
export default AgoraOverlay;
