import { IonCard, IonCardContent } from '@ionic/react';
import { hammer, location, time } from 'ionicons/icons';
import TaskDetails from './TaskDetails';
import TaskHead from './TaskHead';

import styles from './TaskCard.module.scss';

interface Props {
  type: 'selling' | 'buying';
}

const TaskCard: React.FC<Props> = ({ type }) => {
  return (
    <IonCard
      className={type === 'selling' ? styles.taskCardSelling : styles.taskCardBuying}
      routerLink="/task"
      routerDirection="forward"
      button
    >
      <TaskHead type={type} />
      <img alt="Silhouette of mountains" src="https://ionicframework.com/docs/img/demos/card-media.png" />
      {/*<IonCardHeader>
                <IonCardTitle>Card Title</IonCardTitle>
                <IonCardSubtitle>Card Subtitle</IonCardSubtitle>
            </IonCardHeader>*/}
      <IonCardContent>
        <p>Please mow the lawn for me. But it should be perfect, cause' I'm a picky lawn-lower!</p>
      </IonCardContent>

      <TaskDetails
        details={[
          {
            icon: time,
            text: 'In two days',
          },
          {
            icon: location,
            text: '9022 Győr, Apáca u.51. (100m)',
          },
          {
            icon: hammer,
            text: 'Equipment available on site',
          },
        ]}
      />

      {/*<IonButton fill="clear">Details</IonButton>*/}
    </IonCard>
  );
};

export default TaskCard;
