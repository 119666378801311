import hu from "./hu.json";
import en from "./en.json";

const resources: any = {
    hu: {
        translation: hu,
    },
    en: {
        translation: en,
    }
};
export default resources;
