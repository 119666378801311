import React from 'react';
import { IonButton, IonButtons, IonContent, IonHeader, IonModal, IonTitle, IonToolbar } from '@ionic/react';
import FloatingButtons from '../utils/FloatingButtons';

import styles from './FilterHomeModal.module.scss';

interface Props {
  show: boolean;
  handleClose: () => void;
}

const FilterHomeModal: React.FC<Props> = ({ show, handleClose }) => {
  return (
    <IonModal isOpen={show} onDidDismiss={handleClose} className={styles.filterHomeModal}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={handleClose}>Cancel</IonButton>
          </IonButtons>
          <IonTitle>Filters</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">Filter details</IonContent>
      <FloatingButtons>
        <IonButton color="secondary" onClick={handleClose}>
          Cancel
        </IonButton>
        <IonButton onClick={handleClose}>Use</IonButton>
      </FloatingButtons>
    </IonModal>
  );
};

export default FilterHomeModal;
