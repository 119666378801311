import React, { useEffect, useState } from 'react';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonModal,
  IonProgressBar,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import AgoraRTC, { ICameraVideoTrack, IMicrophoneAudioTrack } from 'agora-rtc-sdk-ng';

interface IProps {
  micTrack: IMicrophoneAudioTrack;
  videoTrack: ICameraVideoTrack;
  isOpen: boolean;
  onDidDismiss: () => void;
}

const AgoraModalSettings: React.FC<IProps> = ({ micTrack, videoTrack, isOpen, onDidDismiss }) => {
  const { t } = useTranslation();

  // const [devicesPlayback, setDevicesPlayback] = useState<MediaDeviceInfo[]>([]);
  const [devicesMicrophones, setDevicesMicrophones] = useState<MediaDeviceInfo[]>([]);
  const [devicesCameras, setDevicesCameras] = useState<MediaDeviceInfo[]>([]);
  const [devicesLoading, setDevicesLoading] = useState<boolean>(false);

  const [microphoneLevel, setMicrophoneLevel] = useState<number>(0);

  const fetchDevices = async () => {
    setDevicesLoading(true);
    /*
    const _devicesPlayback = await AgoraRTC.getPlaybackDevices();
    setDevicesPlayback(_devicesPlayback);
    */
    const _devicesMicrophones = await AgoraRTC.getMicrophones();
    setDevicesMicrophones(_devicesMicrophones);
    const _devicesCameras = await AgoraRTC.getCameras();
    setDevicesCameras(_devicesCameras);
    setDevicesLoading(false);
  };

  const handleChangeDevice = (type: 'mic' | 'camera' | 'playback', value: string) => {
    switch (type) {
      case 'mic':
        const foundMic = devicesMicrophones.find((device: MediaDeviceInfo) => device.label === value);
        if (foundMic) {
          micTrack.setDevice(foundMic.deviceId);
        }
        break;
      case 'camera':
        const foundCamera = devicesCameras.find((device: MediaDeviceInfo) => device.label === value);
        if (foundCamera) {
          videoTrack.setDevice(foundCamera.deviceId);
        }
        return;
      /*case 'playback':
        const foundPlayback = devicesPlayback.find((device: MediaDeviceInfo) => device.label === value);
        if (foundPlayback) {
          micTrack.setPlaybackDevice(foundPlayback.deviceId);
        }
        return;*/
      default:
        break;
    }
  };

  useEffect(() => {
    let micLevelInterval = undefined;
    if (isOpen) {
      fetchDevices();

      /*micLevelInterval = setInterval(() => {
        setMicrophoneLevel(micTrack.getVolumeLevel());
      }, 150);*/
    } else {
      if (micLevelInterval) {
        // clearInterval(micLevelInterval);
      }
    }
  }, [isOpen]);
  
  return (
    <IonModal isOpen={isOpen} onDidDismiss={onDidDismiss}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={onDidDismiss}>{t('Close')}</IonButton>
          </IonButtons>
          <IonTitle>{t('Settings')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {/*
        AgoraRTCException: AgoraRTCError NOT_SUPPORTED: your browser does not support setting the audio output device
        <IonSelect
          interface="action-sheet"
          placeholder={t('Select a playback device' as string) ?? ''}
          disabled={devicesPlayback.length === 0 || devicesLoading}
          cancelText={t('Cancel' as string) ?? ''}
          onIonChange={(e) => handleChangeDevice('playback', e.detail.value)}
        >
          {devicesPlayback.map((device: MediaDeviceInfo) => (
            <IonSelectOption value={device.label} key={device.deviceId}>
              {device.label}
            </IonSelectOption>
          ))}
        </IonSelect>
        */}
        {micTrack ? (
          <IonItem>
            <IonLabel position="stacked" color="primary">{t('Microphone' as string) ?? ''}</IonLabel>
            <IonSelect
              interface="action-sheet"
              placeholder={t('Select a microphone' as string) ?? ''}
              disabled={devicesMicrophones.length === 0 || devicesLoading}
              cancelText={t('Cancel' as string) ?? ''}
              value={micTrack ? micTrack.getTrackLabel() : ''}
              onIonChange={(e) => handleChangeDevice('mic', e.detail.value)}
            >
              {devicesMicrophones.map((device: MediaDeviceInfo) => (
                <IonSelectOption value={device.label} key={device.deviceId}>
                  {device.label}
                </IonSelectOption>
              ))}
            </IonSelect>
            <IonProgressBar value={microphoneLevel} style={{ margin: "10px 0" }} />
          </IonItem>
        ) : null}
        {videoTrack ? (
          <IonItem>
            <IonLabel position="stacked" color="primary">{t('Camera' as string) ?? ''}</IonLabel>
            <IonSelect
              interface="action-sheet"
              placeholder={t('Select a camera' as string) ?? ''}
              disabled={devicesCameras.length === 0 || devicesLoading}
              cancelText={t('Cancel' as string) ?? ''}
              value={videoTrack ? videoTrack.getTrackLabel() : ''}
              onIonChange={(e) => handleChangeDevice('camera', e.detail.value)}
            >
              {devicesCameras.map((device: MediaDeviceInfo) => (
                <IonSelectOption value={device.label} key={device.deviceId}>
                  {device.label}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>
        ) : null}
      </IonContent>
    </IonModal>
  );
};

export default AgoraModalSettings;
