import React from 'react';
import {
  AgoraVideoPlayer,
  IAgoraRTCClient,
  IAgoraRTCRemoteUser,
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
  VideoPlayerConfig,
} from 'agora-rtc-react';

import './AgoraUserVideo.scss';

interface IProps {
  users: IAgoraRTCRemoteUser[];
  client: IAgoraRTCClient;
}

const AgoraUserVideo: React.FC<IProps> = ({ users, client }) => {
  const config: VideoPlayerConfig = {
    mirror: false,
    fit: 'contain',
  };

  return (
    <>
      {users.length > 0 &&
        users.map((user: IAgoraRTCRemoteUser) => {
          if (user.videoTrack && user.hasVideo) {
            return <AgoraVideoPlayer className="agora-user-video-stream" config={config} videoTrack={user.videoTrack} key={user.uid} />;
          } else return null;
        })}
    </>
  );
};
export default AgoraUserVideo;
