import { IonCol } from '@ionic/react';
import styles from './Figure.module.scss';

interface Props {
  title: string;
  count: string;
}

const Figure: React.FC<Props> = ({ title, count }) => {
  return (
    <IonCol size="4" className={styles.figure}>
      <h6>{count ?? ''}</h6>
      <p>{title ?? ''}</p>
    </IonCol>
  );
};

export default Figure;
