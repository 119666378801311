import { useState } from 'react';
import { IonButton, IonButtons, IonIcon } from '@ionic/react';
import { filter } from 'ionicons/icons';
import FilterHomeModal from '../components/modals/FilterHomeModal';
import TaskCard from '../components/task/TaskCard';
import PageWrapper from '../layout/PageWrapper';

import './HomePage.scss';

const HomePage: React.FC = () => {
  const [showFilterHomeModal, setShowFilterHomeModal] = useState<boolean>(false);

  return (
    <PageWrapper id="page-home">
      <div className="toolbar-buttons">
        <IonButtons>
          <IonButton onClick={() => setShowFilterHomeModal(true)}>
            <IonIcon icon={filter} slot="start" />
            Filters
          </IonButton>
        </IonButtons>
      </div>

      <TaskCard type="selling" />
      <TaskCard type="buying" />
      <TaskCard type="buying" />
      <TaskCard type="selling" />

      <FilterHomeModal show={showFilterHomeModal} handleClose={() => setShowFilterHomeModal(false)} />
    </PageWrapper>
  );
};

export default HomePage;
