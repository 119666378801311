import { hammer, time, location } from 'ionicons/icons';
import TaskHead from '../components/task/TaskHead';
import TaskDetails from '../components/task/TaskDetails';
import PageWrapper from '../layout/PageWrapper';

import './TaskPage.scss';

const TaskPage: React.FC = () => {
  return (
    <PageWrapper id="page-task" showBackButton>
      <img
        alt="Silhouette of mountains"
        src="https://ionicframework.com/docs/img/demos/card-media.png"
        className="task-image"
      />
      <TaskHead type="selling" />

      <TaskDetails
        details={[
          {
            icon: time,
            text: 'In two days',
          },
          {
            icon: location,
            text: '9022 Győr, Apáca u.51. (100m)',
          },
          {
            icon: hammer,
            text: 'Equipment available on site',
          },
        ]}
      />

      <div className="ion-padding">
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. In ac metus sit amet tellus rutrum ultrices. Etiam
          tempor id nibh vitae rhoncus. Maecenas ultricies, mauris non blandit commodo, metus magna aliquet magna, et
          volutpat justo diam et arcu. Phasellus quam lacus, elementum id est at, varius porttitor libero. Integer
          feugiat, sapien non scelerisque pretium, risus ex molestie orci, viverra egestas est dui in neque. Nam rutrum
          scelerisque tincidunt. Maecenas pulvinar viverra turpis, at auctor justo vehicula ut. Fusce porta viverra
          tortor sit amet tincidunt. Cras dictum ipsum eget nisl porttitor, in pellentesque lacus suscipit.
          <br />
          <br />
          Curabitur laoreet posuere metus, id imperdiet nisl tincidunt vitae. Nulla vitae dapibus diam. Donec id eros
          vitae massa porta porta. Interdum et malesuada fames ac ante ipsum primis in faucibus. Maecenas in eros
          suscipit, facilisis nisi id, vulputate nisi. Nunc sed erat massa. In id maximus lectus, congue accumsan justo.
          Etiam quam erat, molestie quis mi id, facilisis convallis justo. Donec ut tortor rhoncus, pellentesque nisl
          et, eleifend tortor. In tincidunt bibendum orci vitae sagittis. Cras fermentum augue convallis facilisis
          venenatis. Fusce velit urna, porttitor nec gravida convallis, posuere et erat. Vivamus pretium dignissim elit
          euismod tempor. Aenean finibus nisl at erat auctor vestibulum.
        </p>
      </div>
    </PageWrapper>
  );
};

export default TaskPage;
