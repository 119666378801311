import styles from './FloatingButtons.module.scss';

interface Props {
  children?: any;
}

const FloatingButtons: React.FC<Props> = ({ children }) => {
  return <div className={styles.floatingButtons}>{children ?? ''}</div>;
};

export default FloatingButtons;
