import { IonSearchbar } from '@ionic/react';
import AgoraAudience from '../components/agora/AgoraAudience';
import PageWrapper from '../layout/PageWrapper';

const SearchPage: React.FC = () => {
  return (
    <PageWrapper id="page-search">
      <AgoraAudience mode="live" role="audience" />
    </PageWrapper>
  );
};

export default SearchPage;
