import React from 'react';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonText,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import FloatingButtons from '../utils/FloatingButtons';

import styles from './AddTaskModal.module.scss';

interface Props {
  show: boolean;
  handleClose: () => void;
}

const AddTaskModal: React.FC<Props> = ({ show, handleClose }) => {
  return (
    <IonModal isOpen={show} onDidDismiss={handleClose} className={styles.addTaskModal}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={handleClose}>Cancel</IonButton>
          </IonButtons>
          <IonTitle>New task</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonItem>
            <IonLabel position="stacked">
              Title
              <IonText color="danger">*</IonText>
            </IonLabel>
            <IonInput value="" placeholder="Task's title" required />
          </IonItem>
          <IonItem>
            <IonLabel position="stacked">
              Description
              <IonText color="danger">*</IonText>
            </IonLabel>
            <IonTextarea placeholder="Task's description" rows={5} required />
          </IonItem>
        </IonList>
      </IonContent>
      <FloatingButtons>
        <IonButton color="secondary" onClick={handleClose}>
          Cancel
        </IonButton>
        <IonButton onClick={handleClose}>Save</IonButton>
      </FloatingButtons>
    </IonModal>
  );
};

export default AddTaskModal;
