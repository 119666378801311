import { IonIcon } from '@ionic/react';

import styles from './TaskDetail.module.scss';

interface Props {
  icon: string;
  text: string;
}

const TaskDetail: React.FC<Props> = ({ icon, text }) => {
  return (
    <div className={styles.taskDetail}>
      <IonIcon icon={icon} />
      <span>{text}</span>
    </div>
  );
};

export default TaskDetail;
