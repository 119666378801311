import React, { useState } from 'react';
import { createClient, createMicrophoneAndCameraTracks, IAgoraRTCRemoteUser } from 'agora-rtc-react';
import AgoraRTC, { ClientRole, SDK_MODE } from 'agora-rtc-sdk-ng';
import AgoraUserVideo from './AgoraUserVideo';
import { useIonViewDidEnter, useIonViewDidLeave } from '@ionic/react';

import './AgoraHost.scss';

interface IProps {
  mode: SDK_MODE;
  role: ClientRole;
}

// TODO: Move these to .env
const appId: string = '1f211b9caefe4acfbf0a551e12e159e3';
const token: string | null =
  '007eJxTYOjvKrrD35/iNTfW4vA6zYf31le8+RN/5s22Vh+Jw6/mmU9VYDBMMzI0TLJMTkxNSzVJTE5LSjNINDU1TDU0SjU0tUw1br04K7khkJFhQiQbKyMDBIL4rAwlqcUlRgwMAFb5IrE=';

AgoraRTC.setLogLevel(4);

const AgoraHost: React.FC<IProps> = ({ mode, role }) => {
  const useClient = createClient({
    mode: "live",
    codec: 'vp8',
    role: "audience",
  });
  const client = useClient();

  const [users, setUsers] = useState<IAgoraRTCRemoteUser[]>([]);
  const [clientStarted, setClientStarted] = useState<boolean>(false);

  const startClient = () => {
    if (clientStarted) {
      return;
    }

    client.on('user-published', async (user, type) => {
      console.log(`user-published-${type}`);
      await client.subscribe(user, type);
      if (type === 'audio') {
        user.audioTrack?.play();
      }
      if (type === 'video') {
        setUsers((prevUsers) => [...prevUsers, user]);
      }
    });

    client.on('user-unpublished', async (user, type) => {
      console.log(`user-unpublished-${type}`);
      await client.unsubscribe(user, type);
      user.audioTrack?.stop();
      setUsers((prevUsers) => {
        return prevUsers.filter((User) => User.uid !== user.uid);
      });
  });

    client.on('user-left', (user) => {
      console.log('leaving', user);
      setUsers((prevUsers) => {
        return prevUsers.filter((User) => User.uid !== user.uid);
      });
    });

    client.on('user-info-updated', (user, msg) => {
      console.log('user-info-updated', user, msg);
    });


    client.join(appId, 'test2', token).then(() => {
      setClientStarted(true);
    });
  };

  const stopClient = () => {
    if (!clientStarted) {
      return;
    }

    client.leave().then(() => {
      client.removeAllListeners();
      setClientStarted(false);
    });
  };

  useIonViewDidEnter(() => {
    startClient();
  });

  useIonViewDidLeave(() => {
    stopClient();
  });

  return (
    <div className="agora-wrapper">
      <AgoraUserVideo users={users} client={client} />
    </div>
  );
};
export default AgoraHost;
