import React from 'react';
import {
  AgoraVideoPlayer,
  IAgoraRTCClient,
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
  VideoPlayerConfig,
} from 'agora-rtc-react';

import './AgoraLocalVideo.scss';

interface IProps {
  micTrack: IMicrophoneAudioTrack;
  videoTrack: ICameraVideoTrack;
  client: IAgoraRTCClient;
}

const AgoraLocalVideo: React.FC<IProps> = ({ micTrack, videoTrack, client }) => {
  const config: VideoPlayerConfig = {
    mirror: true,
    fit: 'contain',
  };

  return <AgoraVideoPlayer className="agora-local-video-stream" videoTrack={videoTrack} config={config} />;
};
export default AgoraLocalVideo;
