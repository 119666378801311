import {
  IonAvatar,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
} from '@ionic/react';
import { cameraOutline } from 'ionicons/icons';
import Figure from '../components/utils/Figure';
import PageWrapper from '../layout/PageWrapper';

import './ProfilePage.scss';

const ProfilePage: React.FC = () => {
  return (
    <PageWrapper id="page-profile">
      <IonGrid className="profile">
        <IonRow>
          <IonCol size="12">
            <IonAvatar className="avatar">
              <img src="https://ionicframework.com/docs/img/demos/avatar.svg" alt="" />
            </IonAvatar>
            <div className="avatar-upload">
              <IonIcon icon={cameraOutline} />
            </div>
          </IonCol>
        </IonRow>
        <IonRow className="profile-header">
          <IonCol size="12" className="ion-text-center">
            <IonCardTitle>Alan Montgomery</IonCardTitle>
            <IonCardSubtitle>Mobile Team Lead</IonCardSubtitle>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonGrid className="figures ion-no-padding ion-no-margin">
        <IonRow>
          <Figure count="1,386" title="Tasks" />
          <Figure count="849" title="Sold" />
          <Figure count="473" title="Bought" />
        </IonRow>
      </IonGrid>
      <IonList>
        <IonItem detail lines="full" button>
          <IonLabel>Settings</IonLabel>
        </IonItem>
        <IonItem detail lines="full" button>
          <IonLabel>Order history</IonLabel>
        </IonItem>
        <IonItem detail lines="full" button>
          <IonLabel>Logout</IonLabel>
        </IonItem>
      </IonList>
    </PageWrapper>
  );
};

export default ProfilePage;
