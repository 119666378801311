import React from 'react';
import { IonContent, IonPage } from '@ionic/react';
import Header from './Header';

type Props = {
  id?: string;
  logo?: boolean;
  children: any;
  containerClasses?: string;
  hideHeader?: boolean;
  showBackButton?: boolean;
  fullscreen?: boolean;
};

const PageWrapper: React.FC<Props> = ({ id, children, hideHeader, showBackButton, containerClasses, fullscreen }) => {
  return (
    <IonPage id={id ?? 'page-id'} className="ion-page">
      {!hideHeader && <Header showBackButton={showBackButton} />}
      <IonContent fullscreen={fullscreen??false} className={containerClasses ?? ''}>
        {children ?? ''}
      </IonContent>
    </IonPage>
  );
};

export default PageWrapper;
