import { IonAvatar, IonBadge, IonIcon, IonItem, IonLabel } from '@ionic/react';
import { body, build } from 'ionicons/icons';

import styles from './TaskHead.module.scss';

interface Props {
  type: 'selling' | 'buying';
}

const TaskHead: React.FC<Props> = ({ type }) => {
  return (
    <IonItem lines="none" className={styles.taskHead}>
      <IonAvatar slot="start">
        <img alt="Silhouette of a person's head" src="https://ionicframework.com/docs/img/demos/avatar.svg" />
      </IonAvatar>
      <IonLabel>
        <h3>Mow Annie</h3>
        <p>1 day ago</p>
      </IonLabel>
      <IonBadge color={type === 'selling' ? 'success' : 'tertiary'} slot="end">
        <IonIcon icon={type === 'selling' ? body : build} />
        <span>$50</span>
      </IonBadge>
    </IonItem>
  );
};

export default TaskHead;
