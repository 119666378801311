import React from 'react';
import PageWrapper from '../layout/PageWrapper';
import AgoraHost from '../components/agora/AgoraHost';

import './ShopPage.scss';

const ShopPage: React.FC = () => {
  return (
    <PageWrapper id="page-shop" fullscreen={false}>
      <AgoraHost
        mode="live"
        role="host"
      />
    </PageWrapper>
  );
};

export default ShopPage;
