import React, { useEffect, useState } from 'react';
import {
  createClient,
  createMicrophoneAndCameraTracks,
  IAgoraRTCRemoteUser,
} from 'agora-rtc-react';
import AgoraRTC, { ClientRole, ICameraVideoTrack, IMicrophoneAudioTrack, SDK_MODE } from 'agora-rtc-sdk-ng';
import AgoraOverlay from './AgoraOverlay';
import AgoraLocalVideo from './AgoraLocalVideo';

import "./AgoraHost.scss";
import { useIonViewDidEnter } from '@ionic/react';

interface IProps {
  mode: SDK_MODE;
  role: ClientRole;
}

// TODO: Move these to .env
const appId: string = '1f211b9caefe4acfbf0a551e12e159e3';
const token: string | null =
  '007eJxTYOjvKrrD35/iNTfW4vA6zYf31le8+RN/5s22Vh+Jw6/mmU9VYDBMMzI0TLJMTkxNSzVJTE5LSjNINDU1TDU0SjU0tUw1br04K7khkJFhQiQbKyMDBIL4rAwlqcUlRgwMAFb5IrE=';

const AgoraHost: React.FC<IProps> = ({ mode, role }) => {
  const useClient = createClient({
    mode: "live",
    codec: 'vp8',
    role: "host",
  });
  const client = useClient();
  const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks();
  const { ready, tracks } = useMicrophoneAndCameraTracks();

  const [published, setPublished] = useState<boolean>(false);

  AgoraRTC.setLogLevel(4);

  const initHost = async () => {
    console.log(ready, tracks, client);
    if (ready && tracks && client) {
      await client.join(appId, 'test2', token);
    }
  };

  const handlePlayStop = () => {
    if (ready && tracks && client) {
      if (published) {
        client.unpublish(tracks);
        setPublished(false);
      } else {
        client.publish(tracks);
        setPublished(true);
      }
    }
  };

  useEffect(() => {
    initHost();
  }, [ready, tracks, client]);

  /*
  useEffect(() => {
    // function to initialise the SDK
    let init = async () => {
      client.on('user-published', async (user, mediaType) => {
        await client.subscribe(user, mediaType);
        console.log('subscribe success');
        setUsers((prevUsers) => [...prevUsers, user]);
      });

      client.on('user-unpublished', (user, type) => {
        console.log('unpublished', user, type);
        setUsers((prevUsers) => {
          return prevUsers.filter((User) => User.uid !== user.uid);
        });
      });

      client.on('user-left', (user) => {
        console.log('leaving', user);
        setUsers((prevUsers) => {
          return prevUsers.filter((User) => User.uid !== user.uid);
        });
      });

      // TODO: 'test' helyett itt majd a backend által megadott channel név legyen használva
      // '1' vagy 'null' helyett az utoló paraméternél pedig a user id legyen megadva!!!
      /*const user_id = await client.join(appId, 'test', token, 870);
      console.log(user_id);

      // Ez fontos, ha live akarsz lenni akkor ezt kell meghívni
      // if (tracks) await client.publish(tracks);

      console.info("😋 INIT 😋");

      setStart(true);
    };

    if (ready && tracks) {
      init();
    }
  }, [ready, tracks]);
  */

  /*
  const handleStop = async () => {
    if (!tracks) {
      return;
    }

    tracks[0].setMuted(true);
    await client.unpublish([tracks[0]]);
    //tracks[0].close();
    setPublished(false);
  }

  const handlePlayStop = async () => {
    if (tracks) {
      if (!published) {
        await client.publish(tracks);
        /*tracks[0].setMuted(false);
        tracks[1].setMuted(false);
        setPublished(true);
        console.log("✅");
      } else {
        /*tracks[0].setMuted(true);
        tracks[1].setMuted(true);
        await client.unpublish(tracks);
        setPublished(false);
        console.log("❌");
      }
    }
  };
  */

  return (
    <div className="agora-wrapper">
      {ready && tracks ? (
        <>
          <AgoraOverlay micTrack={tracks[0]} videoTrack={tracks[1]} client={client} published={published} handlePlayStop={handlePlayStop} />
          <AgoraLocalVideo micTrack={tracks[0]} videoTrack={tracks[1]} client={client} />
        </>
      ) : null}
    </div>
  );
};
export default AgoraHost;
