import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ConstantAuthToken } from '../../constants/LocalStorageConstants';
import LocalStorage from '../../helpers/LocalStorage';
import { ModelAuth } from '../../models/Auth';

interface SystemState {
  authObject: ModelAuth;
}

const initialState: SystemState = {
  authObject: {
    user: undefined,
    token: LocalStorage.get(ConstantAuthToken),
  },
};

export const systemSlice = createSlice({
  name: 'system',
  initialState,
  reducers: {
    setAuthObject: (state, action: PayloadAction<ModelAuth>) => {
      state.authObject = action.payload;
    },
  },
});

export const { setAuthObject } = systemSlice.actions;
export default systemSlice.reducer;
