import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router';
import {
  IonIcon,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonFab,
  IonFabButton,
  IonBadge,
  useIonLoading,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { addCircle, home, list, personCircle, search } from 'ionicons/icons';
import HomePage from './pages/HomePage';
import SearchPage from './pages/SearchPage';
import TaskPage from './pages/TaskPage';
import AddTaskModal from './components/modals/AddTaskModal';
import ProfilePage from './pages/ProfilePage';
import ShopPage from './pages/ShopPage';
import { useAppDispatch, useAppSelector } from './store/hooks';
import { setAuthObject } from './store/slices/systemSlice';
import LoginPage from './pages/LoginPage';

const Routes: React.FC = () => {
  const authObject = useAppSelector((state) => state.system.authObject);
  const dispatch = useAppDispatch();
  const [presentLoader, dismissLoader] = useIonLoading();

  const [addTaskAlert, setAddTaskAlert] = useState<boolean>(false);

  useEffect(() => {
    // Ha megnyílik az app de már van tokenünk akkor le kell kérdezni a user objectet
    if (authObject.token) {
      presentLoader('Fetching user details...', 1500);
      setTimeout(() => {
        dispatch(
          setAuthObject({
            ...authObject,
            user: 'USER', // TODO: Ide a user object kell majd
          })
        );
        dismissLoader();
      }, 1500);
    }
  }, [authObject, dismissLoader, dispatch, presentLoader]);

  return (
    <IonReactRouter>
      <IonTabs>
        <IonRouterOutlet>
          <Route exact path="/home">
            <HomePage />
          </Route>
          <Route exact path="/search">
            <SearchPage />
          </Route>
          <Route path="/shop">
            <ShopPage />
          </Route>
          <Route path="/profile">{authObject.user === undefined ? <Redirect to="/login" /> : <ProfilePage />}</Route>
          <Route path="/task">
            <TaskPage />
          </Route>
          <Route path="/login">
            <LoginPage />
          </Route>
          <Route exact path="/">
            <Redirect to="/home" />
          </Route>
        </IonRouterOutlet>
        <IonTabBar slot="bottom">
          <IonTabButton tab="home" href="/home">
            <IonIcon icon={home} />
          </IonTabButton>
          <IonTabButton tab="search" href="/search">
            <IonIcon icon={search} />
          </IonTabButton>
          <IonTabButton disabled></IonTabButton>
          <IonTabButton tab="shop" href="/shop">
            <IonBadge color="danger">1</IonBadge>
            <IonIcon icon={list} />
          </IonTabButton>
          <IonTabButton tab="profile" href="/profile">
            <IonIcon icon={personCircle} />
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
      <IonFab vertical="bottom" horizontal="center" slot="fixed">
        <IonFabButton className="center-fab" onClick={() => setAddTaskAlert(true)}>
          <IonIcon icon={addCircle} />
        </IonFabButton>
      </IonFab>
      <AddTaskModal show={addTaskAlert} handleClose={() => setAddTaskAlert(false)} />
    </IonReactRouter>
  );
};

export default Routes;
