import TaskDetail from './TaskDetail';
import styles from './TaskDetails.module.scss';

interface Props {
  details: Array<{ icon: string; text: string }>;
}

const TaskDetails: React.FC<Props> = ({ details }) => {
  return (
    <div className={styles.taskDetails}>
      {details.map((detail: any, key: number) => (
        <TaskDetail key={key} icon={detail.icon} text={detail.text} />
      ))}
    </div>
  );
};

export default TaskDetails;
