import React from 'react';
import { IonBackButton, IonButtons, IonHeader, IonTitle, IonToolbar } from '@ionic/react';

import icon from '../assets/img/icon.svg';

import './Header.scss';

interface Props {
  showBackButton?: boolean;
}

const Header: React.FC<Props> = ({ showBackButton }) => {
  return (
    <IonHeader>
      <IonToolbar>
        {showBackButton && (
          <IonButtons slot="start">
            <IonBackButton defaultHref="/home" text="Back" />
          </IonButtons>
        )}
        <IonTitle>
          <div className="logo">
            <img src={icon} alt="" />
            HumanDroid
          </div>
        </IonTitle>
      </IonToolbar>
    </IonHeader>
  );
};

export default Header;
